export default {
  // methods: {
  //   hitungImt () {
  //     // alert('asdasda')
  //     // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  //     // const berat = this.data.fisik.status_gizi.berat_badan

  //     // console.log(tinggi)
  //     // console.log(berat)

  //     // var tb = parseFloat(tinggi / 100)
  //     // var bb = berat
  //     // var imt = bb / (tb * tb)

  //     // this.data.fisik.status_gizi.imt = imt.toString()
  //     // console.log(imt)
  //   }
  // },
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      label: 'Ruangan',
      // data: [
      //   { text: 'Laki-Laki', value: 'laki-laki' },
      //   { text: 'Wanita', value: 'wanita' }
      // ],
      data: null,
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 4
    }
  },
  form1: {
    p1: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: 'Diagnosis Prabedah'
    },
    p2: {
      widget: 'wtextarea',
      data: null,
      col: 6,
      label: 'Diagnosis Pascabedah'
    },
    l1: {
      widget: 'wlabelkiri',
      data: null,
      col: 12,
      label: 'Keadaan Prabedah'
    },
    p3: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'BB'
    },
    p4: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: null, field: 'auto' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 2,
      label: 'ALERGI'
    },
    p5: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'TD'
    },
    p6: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'RR'
    },
    p7: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'Suhu'
    },
    p8: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'Skala Nyeri'
    },
    l2: {
      widget: 'wlabelkiri',
      data: null,
      col: 12,
      label: 'Pemeriksaan Fisik'
    },
    p9: {
      widget: 'wradio',
      data: [
        { text: 'Normal', value: null, field: 'auto' },
        { text: 'Abnormal', value: null, field: 'auto' }
      ],
      col: 12,
      label: 'Jalan Nafas'
    },
    p10: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Obat lokal anastesi yang digunakan'
    },
    p11: {
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 6,
      label: 'Diencerkan'
    },
    p12: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Jenis Pengenceran'
    },
    p13: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Jumlah obat yang digunakan'
    },
    p14: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Lokasi pemberian lokal anastesi'
    },
    p15: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Jasa pemberian'
    }
  },
  form3: {
    p1: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Kejadian penting selama operasi'
    }
  }
}
